import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import axios from 'axios';
import {contractAdr, getCurrentWalletConnected} from './util/interact.js'
import DrawCanvas from './drawCanvas.js'
import Draggable from 'react-draggable';
import useWindowDimensions from './util/getWindowDimensions.js'

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
function MyTokens() {
  async function getWallet(){
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address)
    return address;
  }
  const web3 = new createAlchemyWeb3(alchemyKey);
    // replace with your Alchemy api key
    const apiKey = "ViTz3JfjpKWJOQ-gbcKj6oKp3Ot1maa1";
    const contract = contractAdr;
    const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs`;
    const startToken = "0";
    const withMetadata = "true";
    const [isShown, setIsShown] = useState(false);
    const [NFTData, setNFTData] = useState([]);
    const [blockNumber, setBN] = useState(0);
    const [isFetched, setFetched] = useState(false);
    const [currentToken, setCurrentToken] = useState({});
    const [mousePos, setMousePos] = useState({});
    const [NFTInfo, setNFTInfo] = useState({});
    const [wallet, setWallet] = useState("");
    const { height, width } = useWindowDimensions();
    const [randomPos, setRandomPos] = useState([]);
    const getBlockNumber = async () => {
      const c = await web3.eth.getBlockNumber();
      setBN(c);
    }
    async function fetchBlock () {
      fetch('nftInfo.json').then((res)=>res.json()).then((data)=>{
        setNFTInfo(data)
       })
    }
    useEffect(async () => {
      if(!isFetched){
      fetchBlock();
      const walletadr = await getWallet();
      var config = {
        method: 'get',
        url: `${baseURL}?contractAddresses[]=${contract}&owner=${walletadr}`,
        headers: { }
      };
      axios(config)
      .then(function(response) {
        setNFTData(response.data);
        setFetched(true);
        getBlockNumber();
      })
      .catch(error => console.log(error));
      }
      const updateMousePos = (e) => {
        setMousePos({x: e.x + 20, y: e.y + 20});
      }
      window.addEventListener('mousemove', updateMousePos)
      window.addEventListener('touchmove', updateMousePos)
      return () => {
        window.removeEventListener('mousemove', updateMousePos)
        window.removeEventListener('touchmove', updateMousePos)
      }
    }, [])
    function generateRandomPos(){
      var n_Coordinates = 50;
      var a = [];
      for ( var i=0; i < n_Coordinates; i++ ) {
        var randomTop = Math.random() * (height - 400) + 0;
        var randomLeft = Math.random() * (width - 200) + 0;
        a.push({randomTop, randomLeft});
      }
      setRandomPos(a);
    }
      return (
        <div className="wrap mytokens" onMouseLeave={() => {setIsShown(false)}}> 
        <div className="inner">       
          {isFetched && NFTData.ownedNfts
      .map((d) => {
      const ipfs = (d.metadata.image).split("ipfs://").pop();
      const url = "https://jakobsitter.mypinata.cloud/ipfs/"+ipfs;
      const owner = d.metadata.owner;
      const tokenuri = d.metadata.animation_url;
      const tokenid = Number(d.id.tokenId);
      const lT = Number(d.metadata.lifetime);
      const info = NFTInfo[tokenid];
      var init;
      if(info && info[5].hex) {
         init = info[5].hex;
      } else {
         init = Number(d.metadata.init);
      }
      const timeleft = (Number(d.metadata.init) + Number(lT)) - blockNumber;
      const timeLeftPerc = blockNumber / (init + lT);
      if(tokenuri == "null" || owner == "0x00") {
      console.log("null")
      console.log(d)
      return false;
      }
    return (
      <React.Fragment>
        <div className="item my" onMouseEnter={() => {setCurrentToken({lt: lT.toString(), id: tokenid.toString(), dead: timeleft.toString()}); setIsShown(true)}}>
        {(typeof(window.BigInt)!="undefined") &&
          <DrawCanvas lifetime={lT} initblock={init} owner={owner} />
        }
        {(typeof(window.BigInt)=="undefined") &&
        <img src={url} />
         }
        </div>
      </React.Fragment>
    );
    }
    )
  }
  {isShown &&
        <div className={"hoverbox"} style={{top: mousePos.y, left: mousePos.x}}>
          token: {currentToken.id} <br />
          lifetime: {currentToken.lt} blocks<br/>
          burn: {currentToken.dead} blocks
        </div>
}
</div>
        </div>
      );
    }
    
    export default MyTokens;
const WalletReducer = (state = {
    walletAddress: "",
    walletSet: false,
    status: ""
} , action) => {
    switch (action.type){
        case 'setConnectedWallet':
            return {
                walletAddress: action.setWalletAdress,
                status: action.setStatus
            }
        case 'DEFAULT':
            return {
                walletAddress: "",
                status: ""
            }

         default:
           return state
    }
    return state;
}

export default WalletReducer
import React, { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  loadContract,
  swichNetwork
} from "./util/interact.js";

const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [addressTokens, setAddressTokens] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    checkTokens().then(result => setAddressTokens(result));
    setWallet(address);
    setStatus(status);
    addWalletListener();
    if(window.ethereum){
    swichNetwork(137)
    }
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("wallet connected");
        } else if (addressTokens > 3) {

        } else {
          setWallet("");
          setStatus("connect your wallet to mint");
        }
      });
    } else {
      setStatus(
        <p>
          <a target="_blank" href={`https://metamask.io/download.html`}>
            you must install metamask, a virtual ethereum wallet, in order to mint tokens.
          </a>
        </p>
      );
    }
  }


  const onMintPressed = async () => {
    const { success, status } = await mintNFT();
    setStatus(status);
  };
  const checkTokens = async () => {
    if (window.ethereum) {
    const c = await loadContract();
    const addressHex = window.ethereum.selectedAddress;
    const balance = await c.methods.balanceOf(addressHex).call();
    return balance;
    }
  }

  return (
    <div className="wrap">
    <div className="minter">
    {addressTokens < 3 &&
    <React.Fragment>
      <button id="mintButton" onClick={onMintPressed}>
        Mint NFT
      </button>
      <p id="status">{status}</p>

      </React.Fragment>
    }
    {addressTokens >= 3 &&
        <p id="status">you currently hold {addressTokens} tokens. transfer one of them to mint a new one.</p>
    }
    {!window.ethereum &&
            <p id="status">{status}</p>
    }
  </div>
    </div>
  );
};

export default Minter;
import axios from 'axios';
import React from 'react';
import { Link } from "react-router-dom";

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const apiKey = process.env.API_KEY;
const contractABI = require("./ABI.json");
const contractAddress = "0x656BCE850c3525d7eE04380f589FDEc23F0ac4e4";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const tokenType = "erc721";
const api = "ViTz3JfjpKWJOQ-gbcKj6oKp3Ot1maa1"
const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${api}`
export const contractAdr = contractAddress;
export const alchApiKey = "ViTz3JfjpKWJOQ-gbcKj6oKp3Ot1maa1";
export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        
        status: "👆🏽 Write a message in the text-field above.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Wallet Connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to Wallet to Mint",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const loadContract = async () => {
  return new web3.eth.Contract(contractABI.abi, contractAddress);
}
export const loadSingleToken = async (tokenId) => {
  var config_meta = {
    method: 'get',
    url: `${baseURL}/getNFTMetadata/?contractAddress=${contractAddress}&tokenId=${tokenId}&tokenType=${tokenType}`,
    headers: { }
  };
  console.log(config_meta)
  const data = axios(config_meta)
  .then(function(response) {
    return(response.data);
  })
  .catch(error => {return error});

  return data;
}
export const getNFTOwner = async (tokenId) => {
  var config_owner = {
    method: 'get',
    url: `${baseURL}/getOwnersForToken/?contractAddress=${contractAddress}&tokenId=${tokenId}`,
    headers: { }
  };
  const owner = axios(config_owner)
  .then(function(response) {
    return(response.data);
  })
  .catch(error => {return error});

  return owner;
}
export const BrowserCheck = () => {
  return(
    <>
    {(!window.ethereum) &&
      "It looks like you are currently using a browser without wallet support. Open this site inside of the MetaMask app or a browser with built-in wallet support (i.e. Brave or Chrome with the MetaMask plugin installed)."
    }
    </>
  );
}
export const getBlockNumber = async () => {
  const c = await web3.eth.getBlockNumber();
  return c;
}
export const mintNFT = async () => {
  window.contract = await new web3.eth.Contract(contractABI.abi, contractAddress);
  const addressHex = window.ethereum.selectedAddress;
  const adrN = parseInt(addressHex, 16);

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods
      .mintNFT(window.ethereum.selectedAddress)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    console.log(txHash)
    return {
      success: true,
      status:
      <React.Fragment>minted! check out your transaction on <a href={`https://polygonscan.com/tx/${txHash}`}>polygonscan</a> or find your token under the <Link to="/mytokens">"my tokens"</Link> overview. </React.Fragment>
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};
const getNetworkId = async () => {
  const currentChainId = await web3.eth.net.getId()
  return currentChainId
}
export const getNFTInfo = async (t) => {
  const c = await new web3.eth.Contract(contractABI.abi, contractAddress);
  const i = await c.methods.getNFTInfo(t).call();
  console.log(i)
  return i
}
export const swichNetwork = async (chainId) => {
  if (window.ethereum.networkVersion !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(chainId) }]
          });
        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: 'Polygon',
                  chainId: web3.utils.toHex(chainId),
                  nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                  rpcUrls: ['https://polygon-rpc.com/']
                }
              ]
            });
          }
        }
      }
}

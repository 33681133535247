import './App.css';
import Minter from './Minter'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";

import {
  BrowserRouter,
  Switch,
  Route,
  Routes,
  Link,
  useParams
} from "react-router-dom";
import Bar from "./Bar.js";
import Gallery from "./Gallery.js"
import GallerySingle from "./GallerySingle.js"
import SingleScreen from "./SingleScreen.js"
import MyTokens from "./MyTokens.js"
import Redeem from "./Redeem.js"
import Info from "./Info.js"
function App() {
  useEffect(() => {
    document.title = 'Lifetime';
  });
  return (
    <div className="app">
      <BrowserRouter>
      <Bar />

      <Routes>
        <Route path="/">
          <Route index element={<Gallery />} />
          <Route path="mint" element={<Minter />} />
          <Route path="info" element={<Info />} />
          <Route path="/token/:id" element={<GallerySingle />} />
          <Route path="/mytokens" element={<MyTokens />} />
          <Route path="/redeem/:id" element={<Redeem />} />
          <Route path="/screendisplay/:id" element={<SingleScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import axios from 'axios';
import {contractAdr} from './util/interact.js'
import DrawCanvas from './drawCanvas.js'
import { loadSingleToken, getNFTOwner, getBlockNumber, loadContract, getNFTInfo } from './util/interact.js';
import Switch from './UI/Switch.js'
import { Flipper, Flipped } from 'react-flip-toolkit'
import Draggable  from 'react-draggable';
import useWindowDimensions from './util/getWindowDimensions.js'
require("dotenv").config();

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
function Gallery() {
  const web3 = new createAlchemyWeb3(alchemyKey);
    // replace with your Alchemy api key
    const apiKey = "ViTz3JfjpKWJOQ-gbcKj6oKp3Ot1maa1";
    const contract = contractAdr;
    const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${apiKey}/getNFTsForCollection/`;
    const startToken = "0";
    const withMetadata = "true";
    const { height, width } = useWindowDimensions();
    const [isShown, setIsShown] = useState(false);
    const [NFTData, setNFTData] = useState([]);
    const [NFTInfo, setNFTInfo] = useState([]);
    const [blockNumber, setBN] = useState(0);
    const [randomOrder, setRO] = useState(0);
    const [isFetched, setFetched] = useState(false);
    const [currentToken, setCurrentToken] = useState({});
    const [mousePos, setMousePos] = useState({});
    const [deadMode, setDeadMode] = useState(false);
    const [randomPos, setRandomPos] = useState([]);
    const [zIndex, setZIndex] = useState(0);
    const [checkMobile, setMobile] = useState(false);
    const getBlockNumber = async () => {
      const c = await web3.eth.getBlockNumber();
      setBN(c);
    }
    async function getNFTdata(){ 
      const c = await loadContract();
      const tokensMinted1 = await c.methods._tokenIds().call();

      var a = [];
      for (var i = 0; i < tokensMinted1; i++) {
        const info = await c.methods.getNFTInfo(i).call();
        a.push(info)
      }
    }
    async function fetchBlock () {
      fetch('nftInfo.json').then((res)=>res.json()).then((data)=>{
        setNFTInfo(data)
       })
      var config = {
        method: 'get',
        url: `${baseURL}?contractAddress=${contract}&withMetadata=${withMetadata}`,
        headers: { }
      };
      axios(config)
      .then(function(response) {
        console.log(config)
        var array = response.data;
        const newarray = array.nfts.sort(() => 0.5 - Math.random())
        setNFTData(newarray);
        console.log(response.data)
        console.log(NFTData);
        setFetched(true);
        getBlockNumber();
      })
      .catch(error => console.log(error));
    }
    useEffect(() => {
      if(!isFetched){
      fetchBlock();
      generateRandomPos();
      }
      document.body.classList.add('overflowhidden')
      const updateMousePos = (e) => {
        setMousePos({x: e.x + 20, y: e.y + 20});
      }
      window.addEventListener('mousemove', updateMousePos)
      window.addEventListener('touchmove', updateMousePos)
      return () => {
        window.removeEventListener('mousemove', updateMousePos)
        window.removeEventListener('touchmove', updateMousePos)
      }
    }, [])
    useEffect(() => {
      return () => {
        document.body.classList.remove('overflowhidden');
      }
    }, [])
    function generateRandomPos(){
      if(window.innerWidth <= 900) {
        setMobile(true)
      }
      var n_Coordinates = 100;
      var a = [];
      if(window.innerWidth <= 900) {
        var box_size = (width/100) * 50;
      } else {
        var box_size = (width/100) * 30;
      }
      console.log(box_size)
      for ( var i=0; i < n_Coordinates; i++ ) {
        var randomTop = ((Math.random() * (height-box_size-50)) + 60);
        var randomLeft = ((Math.random() * (width-box_size)) + 0);
        var zIndex = 0;
        a.push({randomTop, randomLeft, zIndex});
      }
      setRandomPos(a);
      console.log(randomOrder)
    }
    const filter = (f) => {
      if(deadMode) {
      return (Number(f.metadata.lifetime) + Number(f.metadata.init)) < blockNumber
      } else {
        return (Number(f.metadata.lifetime) + Number(f.metadata.init)) > blockNumber
      }
    };
    var counter = 0;
      return (
        <div className="wrap">
            {isFetched &&
        <Draggable disabled={checkMobile}>
          <div className="info" style={{ position: 'absolute',
      top: 100,
      left: 50}}>
        Lifetimes are ERC-721 tokens living on the Polygon blockchain. Equipped with an inner expiry clock, they are assigned a lifetime measured in blocks on mint. Utilizing block time, their lifespan is directly correlated to the infrastructure that they live within. To keep them alive, they must be transferred to another wallet within their given lifetime. They are free to mint and have a cap of 1,000 tokens. <a href="/info">More info →</a></div>
        </Draggable>
            }
        <div className='inner'>
        <div className="toggleView">
          <div className="label">
            {deadMode ? 'dead' : 'alive'}
          </div>
          
          <div className="switch_wrap"><Switch isOn={deadMode}
        handleToggle={() => setDeadMode(!deadMode)}/></div>
        </div>    
        <Flipper flipKey='foo' spring={{ stiffness: 280, damping: 22 }} scale> 
          {isFetched && NFTData
    .slice(0, 10)
    .map((d) => {
      if(d.metadata.image) {
      const tokenuri = d.metadata.animation_url;
      const ipfs = (d.metadata.image).split("ipfs://").pop();
      const url = "https://ipfs.io/ipfs/"+ipfs;
      const owner = d.metadata.owner;
      const lT = Number(d.metadata.lifetime);
      const tokenid = Number(d.id.tokenId);
      const info = NFTInfo[tokenid];
      var init;
      if(info && info[5].hex) {
         init = info[5].hex;
      } else {
         init = Number(d.metadata.init);
      }
    
      //const i_init_block = getNFTInfo(tokenid);
      //console.log(i_init_block.i_initBlock)
      const timeleft = (Number(d.metadata.init) + Number(lT)) - blockNumber;
      const timeLeftPerc = blockNumber / (init + lT);
      let z = 0;
      var s = {
        position: 'absolute',
        top: randomPos[counter].randomTop,
        left: randomPos[counter].randomLeft,
        zIndex: randomPos[counter].zIndex
      }
      if(tokenuri == "null") {
      console.log("null")
      return false;
      }
      function setZindex() {
        let temp_state = [...randomPos];
        let temp_element = { ...randomPos[tokenid] };
        var max = Math.max(...temp_state.map(o => o.zIndex));
        temp_element.zIndex = max+1;
        temp_state[tokenid] = temp_element;
        setRandomPos( temp_state );
      }
      /*if(deadMode && timeleft < 0) {
        return false;
      }*/
      counter+=1;
      
    return (
      <React.Fragment>
        <Draggable onStart={() => setZindex()} disabled={checkMobile}>
        <div className="item" style={s} onMouseEnter={() => {setCurrentToken({lt: lT.toString(), id: tokenid.toString(), dead: timeleft.toString(), owner: d.metadata.owner}); setIsShown(true)}} onMouseLeave={() => {setIsShown(false)}}>
        {(typeof(window.BigInt)!="undefined") &&
          <img src={url} />
        }
        {(typeof(window.BigInt)=="undefined") &&
        <img src={url} />
         }
        </div>
        </Draggable>
      </React.Fragment>
    );
    }
  }
    )
  }
  {isShown &&
        <div className={"hoverbox"} style={{top: mousePos.y, left: mousePos.x}}>
          token: {currentToken.id} <br />
          lifetime: {currentToken.lt} blocks<br/>
          burn: {currentToken.dead} blocks<br />
          owner: {currentToken.owner}
        </div>
}
      </Flipper>
        </div>
        </div>
      );
    }
    
    export default Gallery;
import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import axios from 'axios';
import {contractAdr} from './util/interact.js'
import DrawCanvas from './drawCanvas.js'
import { loadSingleToken, getNFTOwner, getBlockNumber, loadContract } from './util/interact.js';
import Switch from './UI/Switch.js'
import { Flipper, Flipped } from 'react-flip-toolkit'
import Draggable  from 'react-draggable';
import useWindowDimensions from './util/getWindowDimensions.js'
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
function Info() {
      return (
        <div className="wrap info">
          <div className="inner">
           <div className="infobox">
          <p>
          Much inspired by, iterating on and referencing to, Sarah Friend’s <a className="italic" href="https://lifeforms.supply">Lifeforms</a>, Lifetime tokens are born with an inner expiry clock. To keep a token alive, it needs to be transferred to another address within a certain time frame. 
          </p>
          <p>
          Utilizing block time instead of conventional time, their lifetime ranges from 2,000,000 to 5,000,000 blocks, and are generated on a per-token basis in the smart contract. Block time defines the time it takes to create a new block, and is the result of a number of factors including block complexity, computing power and overall network traffic. It’s the native clock of a blockchain system, and is the validation mechanism for forks, deployments and transactions in general. On Polygon, block time averages around 2,3 seconds. Lifetime tokens, and their lifespan, are relative to the system that they live within.
          </p><p>
          If a token is not transferred to another wallet within its assigned lifetime, it will burn and die. A token’s lifetime is constant, and will only reset but not change when transferred. 
</p><p>
          Each token comes with unique, on-chain visuals generated from its assigned lifetime, initial minting block and owner address. That means that parts of its appearance will change when it has a new owner. When you connect your wallet in the upper right corner you will see your wallet’s unique visuals that can also be found on the NFT itself.
</p><p>
Lifetimes live on the Polygon network and are free to mint (you only pay gas fees). The contract has a cap of 1,000 tokens. One wallet can hold three tokens at a time.
</p>
<p>
If you have questions or experience issues, please get in touch with me at j@jakobsitter.com.
</p>
</div>

           </div>
        </div>
      );
    }
    
    export default Info;
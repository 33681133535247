/* global BigInt */

import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import axios from 'axios';
import { loadSingleToken, getNFTOwner, getBlockNumber, loadContract } from './util/interact.js';
require("dotenv").config();


function DrawCanvas(props) {
    //console.log(props.tokenid)
    //const i = await c.methods.getNFTInfo().call();
    //console.log(i)
    const canvas = React.useRef();
    const owner_top = props.owner;
    const lT_top = props.lifetime;
    const init_top = props.initblock;
    function Draw(ctx){
            const width = 1000;
            const height = 1000;
            const tokenId = 2;
            const transfers = 3;
            var xF, yF;
            var o = BigInt(owner_top);
            var cB = 26526325,
                iB = BigInt(init_top),
                lT = BigInt(lT_top),
                o = o.toString(),
                _n1 = BigInt(2 * Number(iB) * Number(lT)).toString(),
                _n2 = BigInt(Number(o) * Number(o) * Number(lT)).toString();
            var _n3 = BigInt(2 * Number(lT) * Number(lT) * Number(o)).toString();
            var n1 = _n1 + _n1 + _n1 + _n1 + _n1 + _n1 + _n1 + _n1 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n1 + _n1 + _n1 + _n1 + _n1;
            var n2 = _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n1 + _n1 + _n1 + _n1 + _n1 + _n1 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n2 + _n1 + _n1 + _n1 + _n1 + _n1 + _n1;
            var n3 = _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3 + _n3;
            const xDim = 1;
            const yDim = 0.8;
            const owner2 = o + o + o + o + o + o + o + o + o + o + o + o;
            function circleCoordinates(style, radius, steps, centerX, centerY, number, rot, grd, seed) {
                let xCoords = [];
                let yCoords = [];
                let increasementX = 1;
                let increasementY = 1;
                let counter = 0;
                for (let i = 0; i < steps; i++) {
                    if (counter == 15) {
                        counter = 0;
                        increasementX = ((Number(number[i]) + Number(number[i + 1]) + Number(number[i + 2])) / 3) * 1.1;
                        increasementY = ((Number(number[i + 3]) + Number(number[i + 4]) + Number(number[i + 5])) / 3) * 1.1;
                    } else {
                        increasementX = increasementX * 1.1;
                        increasementY = increasementY * 1.1;
                    }
                    if (increasementX > 5) {
                        xF = 2.1;
                    } else {
                        xF = 1.9;
                    }
                    if (increasementY > 1) {
                        yF = 2.1;
                    } else {
                        yF = 1.9;
                    }
                    xCoords.push((radius * Math.cos(xF * Math.PI * (i / steps)) - increasementX) * xDim);
                    yCoords.push((radius * Math.sin(yF * Math.PI * (i / steps)) - increasementY) * yDim);
                    counter += 1;
                }
                ctx.translate(width / 2, height / 2);
                ctx.rotate(rot);
                ctx.moveTo(xCoords[0], yCoords[0]);
                ctx.beginPath();
                for (let i = 0; i < steps; ) {
                    ctx.arcTo(xCoords[i], yCoords[i], xCoords[i + 1], yCoords[i + 1], 1);
                    i += 4;
                }
                ctx.lineTo(xCoords[0], yCoords[0]);
                if (style == "g") {
                    var grd1 = ctx.createLinearGradient(20, 200, 200, 0);
                    grd1.addColorStop(0, getRandomColor(number, 20));
                    grd1.addColorStop(1, getRandomColor(number, 50));
                    ctx.fillStyle = grd1;
                    ctx.rotate(rot / 5);
                    ctx.fill();
                    ctx.shadowBlur = 0;
                    ctx.shadowColor = "transparent";
                }
                if (style == "b") {
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = "#000";
                    ctx.stroke();
                }
                ctx.closePath();
                ctx.save();
                ctx.setTransform(1, 0, 0, 1, 0, 0);
                return { xCoords, yCoords };
            }
            function createSimplifiedShape(n, posX, posY, g, x, rot) {
                let steps = 180;
                let xCoords = [];
                let yCoords = [];
                let increasementX = 1;
                let increasementY = 1;
                let counter = 0;
                for (let i = 0; i < steps; i++) {
                    if (counter == 15) {
                        counter = 0;
                        increasementX = ((Number(n[i]) + Number(n[i + 1]) + Number(n[i + 2])) / 3) * 1.1;
                        increasementY = ((Number(n[i + 3]) + Number(n[i + 4]) + Number(n[i + 5])) / 3) * 1.1;
                    } else {
                        increasementX = increasementX * 1.1;
                        increasementY = increasementY * 1.1;
                    }
                    if (increasementX > 5) {
                        xF = 2.1;
                    } else {
                        xF = 1.9;
                    }
                    if (increasementY > 1) {
                        yF = 2.1;
                    } else {
                        yF = 1.9;
                    }
                    xCoords.push(posX + (300 / (2 + n[5 + x] / 2)) * Math.cos(xF * Math.PI * (i / steps)) - increasementX);
                    yCoords.push(posY + (300 / (2 + n[5 + x] / 2)) * Math.sin(yF * Math.PI * (i / steps)) - increasementY);
                    counter += 1;
                }
                ctx.translate(width / 2, height / 2);
                ctx.rotate(rot);
                ctx.moveTo(xCoords[0], yCoords[0]);
                ctx.beginPath();
                for (let i = 0; i < steps; ) {
                    ctx.arcTo(xCoords[i], yCoords[i], xCoords[i + 1], yCoords[i + 1], 0);
                    i += 12;
                }
                var grd = ctx.createRadialGradient(posX, posY, 5, posX, posY, 80);
                grd.addColorStop(0, getRandomColor(n, 10 + x));
                grd.addColorStop(1, getRandomColor(n, 50 + x));
                ctx.lineWidth = 0;
                ctx.fillStyle = grd;
                ctx.fill();
                ctx.closePath();
                ctx.save();
                ctx.setTransform(1, 0, 0, 1, 0, 0);
            }
            function getRandomColor(n, x) {
                var letters = "0123456789ABCDEF";
                var color = "#";
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Number("0." + n[i + x]) * 16)];
                }
                return color;
            }
            function createGrad(x1, x2) {
                var grd = ctx.createLinearGradient(300, 300, 700, 0);
                grd.addColorStop(0, getRandomColor(n1, x1));
                grd.addColorStop(0.5, getRandomColor(n2, x2));
                grd.addColorStop(1, getRandomColor(n1, x1));
                return grd;
            }
            function addCircle(r, seed, g, n, rot, numO, rot2) {
                let steps = 180;
                var posX = width / 2;
                const coords = circleCoordinates("b", r, 360, posX, posX, n, rot2);
                for (var i = 1; i < numO; i++) {
                    createSimplifiedShape(n, coords.xCoords[(360 / numO) * i], coords.yCoords[(360 / numO) * i], g, i, rot);
                }
            }
            let seed1 = 0,
                seed2 = 0,
                seed3 = 0;
            let r = 0,
                velocity = 1;
            function mainLoop() {
                ctx.clearRect(0, 0, width, height);
                ctx.fillStyle = "transparent";
                ctx.fillRect(0, 0, width, height);
                if(!props.onlyOwner){
                addCircle(400, seed2, 0, n1, 0, 5, 0);
                }
                addCircle(390, seed2, 0, owner2, 0, 4, 0);
            }
            mainLoop();

        }
    useEffect(() => {
        const ctx = canvas.current.getContext('2d');
        Draw(ctx);
    });
    return (
        <canvas ref={canvas} height={1000} width={1000} />
    );
  }
  export default DrawCanvas;
import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import axios from 'axios';
import { loadSingleToken, getNFTOwner, getBlockNumber, loadContract } from './util/interact.js';
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
function SingleScreen() {
  const { id } = useParams();
  const [NFTData, setNFTData] = useState([]);
  const [NFTOwner, setNFTOwner] = useState([]);
  const [isFetched, setFetched] = useState(false);
  const [blockNumber, setBlockNumber] = useState(false);
  const [counter, setCounter] = useState(0);
  const [totalNFTs, setTotal] = useState(0);
  async function getDetails(){
    const c = await loadContract();
    const tokensMinted1 = await c.methods._tokenIds().call();
    return tokensMinted1;
  }
  useEffect(async () => {
    const t = await getDetails();
    console.log(t)
    if(!isFetched){
      fetchNFT(id);
    }
    setInterval(() => {
      //totalNFTs
      console.log(totalNFTs)
      const randid = Math.floor((Math.random() * t) + 0);
      fetchNFT(randid);
    }, 30000);
    //console.log(NFTData)
  }, []);
  async function randNum() {
    const randid = Math.floor(Math.random() * 6) + Number(totalNFTs);
    return randid;
  }
  async function fetchNFT(_id) {
    const metadata = await loadSingleToken(String(_id));
    const owner = await getNFTOwner(_id.toString());
    const bn = await getBlockNumber();
    console.log(owner)
    setNFTData(metadata);
    setNFTOwner(owner);
    setBlockNumber(bn);
    setFetched(true);
    console.log(metadata)
  }
      return (
        <>
        <div className="wrap">          
        {isFetched &&
        <div className="item single_screen">
        <iframe src={NFTData.metadata.animation_url} scrolling="no" />
        <div className="info">
        token: {NFTData.id.tokenId}<br/>
        owner: {NFTData.metadata.owner}<br/>
        lifetime: {NFTData.metadata.lifetime}<br/>
        burn in: {(Number(NFTData.metadata.init) + Number(NFTData.metadata.lifetime)) - blockNumber} blocks<br/>
        </div>
        </div>
        }
        </div>
        </>
      );
    }
    
    export default SingleScreen;
import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { BrowserCheck } from './util/interact.js';
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
function Redeem() {
  const { id } = useParams();
  const [redeemCode, setRedeemCode] = useState("Type your redeem code here");
  const [redeemData, setRedeemData] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  const [status, setStatus] = useState("idle");

  const checkRedeem = async () => {
    if(!isFetched) {
    var config = {
      method: 'get',
      url: `https://lifetimeminter.jakobsitter.com/redeem/`+id,
      headers: { }
    };
    axios(config)
    .then(function(response) {
      setRedeemData(response.data);
      console.log(redeemData);
      setIsFetched(true);
    })
    .catch(error => console.log(error));
  }
  }
  const handleChange = (event) => {
    setRedeemCode(event.target.value);
  }
  const transferToken = async (uuid) => {
    const addressHex = window.ethereum.selectedAddress;
    const adrN = parseInt(addressHex, 16);
    const c = { "uuid": uuid, "address": addressHex };
    const response = await axios.post('https://lifetimeminter.jakobsitter.com/transfer/', c);
    console.log(response)
    if(response.status == 200){
    setStatus("success")
    } else {
      setStatus("fail")
    }
    return response;
  }
  const handleRedeem = async() => {
    if(window.ethereum) {
    setStatus("load")
    const t = await transferToken(id);
    console.log(t)
    } else {
      alert("You need to connect to wallet")
    }
  }
  useEffect(() => {
    checkRedeem();
  }, );
    return (
     <div className='wrap redeem'>
       {redeemData &&
       <>
       {!window.ethereum &&
       <div className='helper'>
         To redeem a Lifetime you need a MetaMask (or compatible) wallet. If you do not yet have one, follow the steps listed <a href="https://www.coindesk.com/learn/how-to-set-up-a-metamask-wallet/">here</a>. Once set up, open this page inside of the MetaMask browser, connect to your wallet in the upper right corner and have your token airdropped to you for free!
       </div>
      }
       <div class="redeem">
       {status == "idle" &&
       <>
       <button id="mintButton" onClick={handleRedeem}>
        Redeem Lifetime
      </button>
      <p id='status' class="code">Code: {id} ✅</p>
      </>
      }
      {status == "load" &&
      "Sending your token..."
      }
      {status == "success" &&
      "Success! Find your token in the *my token* section"
      }
      {status == "fail" &&
      "Something went wrong. Please try again."
      }
      </div>
       </>
      }
     </div>
      );
    }
    
    export default Redeem;
import React, { useEffect, useState } from 'react';
import { Base64 } from "js-base64";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import DrawCanvas from './drawCanvas.js'
import axios from 'axios';
import { loadSingleToken, getNFTOwner, getBlockNumber, loadContract } from './util/interact.js';
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
function GallerySingle() {
  const { id } = useParams();
  const [NFTData, setNFTData] = useState([]);
  const [NFTData2, setNFTData2] = useState([]);
  const [NFTOwner, setNFTOwner] = useState([]);
  const [isFetched, setFetched] = useState(false);
  const [blockNumber, setBlockNumber] = useState(false);
  async function getNFT(){
    const c = await loadContract();
    const tokenInfo = await c.methods.getNFTInfo(id).call();
    console.log(tokenInfo)
  }
  useEffect(() => {
    //getNFT();
    if(!isFetched){
    }
    console.log(NFTData)
  });
  if(!isFetched){
    fetchNFT();
  }
  async function fetchNFT() {
    const metadata = await loadSingleToken(id.toString());
    const owner = await getNFTOwner(id.toString());
    const bn = await getBlockNumber();
    console.log(owner)
    setNFTData(metadata);
    setNFTOwner(owner);
    setBlockNumber(bn);
    setFetched(true);
  }
      return (
        <>
        <div className="wrap">          
        {isFetched &&
        <div className="item single_view">
        <iframe src={NFTData.metadata.animation_url} scrolling="no" />
        <div className="info">
        token: {NFTData.metadata.name}<br/>
        lifetime: {NFTData.metadata.lifetime}<br/>
        burn: {(NFTData.metadata.init + NFTData.metadata.lifetime) - blockNumber}<br/>
        owner: {NFTOwner.owners[0]}
        </div>
        </div>
        }
        </div>
        </>
      );
    }
    
    export default GallerySingle;
import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Draggable from 'react-draggable';
import DrawCanvasOwner from './drawCanvasOwner.js'

import {
    connectWallet,
    getCurrentWalletConnected,
    contractAdr,
    loadContract
  } from "./util/interact.js";
  import Ticker from 'react-ticker';
  import { useLocation } from 'react-router-dom';    
  const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
  require("dotenv").config();
  const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
function Bar() {
  const location = useLocation();
  const web3 = new createAlchemyWeb3(alchemyKey);
  const apiKey = "ViTz3JfjpKWJOQ-gbcKj6oKp3Ot1maa1";  
  async function getDetails(){
    const c = await loadContract();
    const tokensMinted1 = await c.methods._tokenIds().call();
    const tokensAlive1 = await c.methods.alive().call();
    const cap1 = await c.methods.cap().call();
    setContractDetails({"minted": tokensMinted1, "alive": tokensAlive1, "cap": cap1});
    console.log(contractDetails)
  }
  const getBlockNumber = async () => {
    const c = await web3.eth.getBlockNumber();
    setBN(c);
  }
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [contractDetails, setContractDetails] = useState({});
  const [BN, setBN] = useState(0);

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    getDetails();
    setWallet(address);
    setStatus(status);
    getBlockNumber();
    setInterval(() => {
      getBlockNumber();
    }, 2000);
    
    addWalletListener();
  }, []);
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("Wallet Connected");
        } else {
          setWallet("");
          setStatus("Connect to Wallet");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };
    if(location.pathname.startsWith("/screendisplay")){
      return false;
    } else {
    return (
      <React.Fragment>
        <Draggable>
      <div className="contract_info">
      <div className='item'>minted: {contractDetails.minted} / {contractDetails.cap}</div>
      <div className='item'>alive: {contractDetails.alive}</div>
      <div className='item'>dead: {contractDetails.minted - contractDetails.alive}</div>
      <div className='item'>current block time: {BN}</div>
      </div>
      </Draggable>
      <div className="bar">
           <div className="item"><NavLink activeClassName="active" to="/">start</NavLink></div>
          <div className="item"><NavLink activeClassName="active" to="/mint">mint</NavLink></div>
          {walletAddress.length > 0 &&
          <React.Fragment>
                    <div className="item"><NavLink activeClassName="active" to="/mytokens">my tokens</NavLink></div>
                    <div className="profileCanvas"><DrawCanvasOwner size="100" lifetime={0} initblock={0} owner={String(walletAddress)} onlyOwner={true} /></div>
          </React.Fragment>
          }
          {(window.innerWidth > 900) ? (
          <button id="walletButton" className="item" onClick={connectWalletPressed}>
          {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
          ) : (
            <button id="walletButton" className="item" onClick={connectWalletPressed}>
          {walletAddress.length > 0 ? (
          "Connected"
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
          )
        }
      </div>
      </React.Fragment>
    );
    }
  }
  
  export default Bar;
  